<template>
  <main>
    <section
      id="mecenes"
      class="py-4"
    >
      <b-container>
        <h1 class="text-center mt-4 mb-5" v-html="$t('sponsors.title')" />
        <b-row
          align-h="center"
          class="mb-5"
        >
          <b-col
            lg="8"
            xl="9"
          >
            <b-card class="fb-m2">
              <div v-html="$t('sponsors.desc', { step1: formatMoney($t('levels.level1')['start']), end_step_1: formatMoney($t('levels.level1')['end']), step1_chars_max: formatNumber($t('levels.level1')['caracters_max']), step2: formatMoney($t('levels.level2')['start']), step2_chars_max: formatNumber($t('levels.level2')['caracters_max']) })" />
            </b-card>
          </b-col>
        </b-row>
        <partner
          v-for="partner in Object.keys(this.$t('partners'))"
          :key="partner"
          :partner="partner"
        />
      </b-container>
    </section>
  </main>
</template>

<script>
import Partner from '../components/Partner.vue';

export default {
  components: {
    Partner,
  },
  methods: {
    formatMoney(amount) {
      return new Intl.NumberFormat(undefined, { style: 'currency', currency: 'EUR', maximumSignificantDigits: 1 }).format(amount);
    },
    formatNumber(amount) {
      return new Intl.NumberFormat().format(amount);
    }
  }
};
</script>

<style lang="scss">
#sponsors {
  main img {
    object-fit: scale-down;
    background: #fff;
    border-radius: 100px;
    width: 200px;
    height: 200px;

    &[src$='aft-rn.jpg'] { background: #efc100; }
    &[src$='picardie.png'] { background: #8bbc00; }
    &[src$='albatros.jpg'] { background: #0f2439; }
  }

  h2 {
    &::after {
      display: none;
    }
  }

  #mecenes {

    h1 {
      color: var(--f-o9);
      &::after {
        border: 3px solid var(--f-o9);
      }
    }

    h2 a {
      color: var(--f-g8);
    }

    img { border: 5px solid var(--f-g3); }

    .row:not(:first-of-type) {
      border-top: 1px solid;
      border-image: radial-gradient(var(--f-g7), transparent) 1;
    }

    .alpha {
      background: var(--f-g2);
    }

    .feb-v2 {
      background: var(--f-o2);

      h2 a {
        color: var(--f-o8);
      }

      img { border: 5px solid var(--f-o3); }

      &.row {
        border-top: 1px solid;
        border-image: radial-gradient(var(--f-o7), transparent) 1;
      }
    }
  }
}

small.date-ajout {
  display: none; // Don't show when they were added for now
}
</style>
