<template>
  <b-row
    :id="partner"
    :class="Number($t(`partners.${partner}.level`)) >= 500 ? 'feb-v2' : 'alpha'"
    class="pt-4 pb-2"
  >
    <b-col
      lg="4"
      xl="3"
      class="text-center"
    >
      <!-- Logo -->
      <p
        aria-hidden="true"
        class="text-center"
      >
        <a
          :href="$t(`partners.${partner}.link`)"
        >
          <img
            :src="`${$t('baseurl')}img/sponsors/${$t(`partners.${partner}.img`)}`"
            alt=""
          />
        </a><br />
        <span v-if="$te(`partners.${partner}.subpartner`)">et ses partenaires&nbsp;:</span>
      </p>
      <div v-if="$te(`partners.${partner}.subpartner`)">
        <p
          v-for="(subpartner, subindex) in $t(`partners.${partner}.subpartner`)"
          :key="subindex"
        >
          <a
            aria-hidden="true"
            :href="subpartner.link"
          >
            <img
              :src="`${$t('baseurl')}img/sponsors/${subpartner.img}`"
              alt=""
            />
          </a>
          <br />
          <small>{{ subpartner.title }}</small>
        </p>
      </div>

      <!-- Date -->
      <small
        v-if="$te(`partners.${partner}.since`)"
        class="date-ajout"
      >
        Ajouté le
        <time :datetime="$t(`partners.${partner}.since`)">
          {{ $t(`partners.${partner}.since`) | localeDate }}
        </time>
      </small>
    </b-col>

    <!-- Description -->
    <b-col
      lg="8"
      xl="9"
    >
      <h2>
        <a
          :href="$t(`partners.${partner}.link`)"
          v-html="$t(`partners.${partner}.title`)"
        ></a>
      </h2>

      <!-- Raw link -->
      <p aria-hidden="true">
        <a
          :href="$t(`partners.${partner}.link`)"
          v-text="$t(`partners.${partner}.link`)"
        ></a>
      </p>
      <div
        v-if="$te(`partners.${partner}.${$route.meta.lang}`)"
        v-html="$t(`partners.${partner}.${$route.meta.lang}`)"
      ></div>
      <div
        v-else
        v-html="$t(`partners.${partner}.fr`)"
      ></div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  filters: {
    localeDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: 'numeric', month: 'long', day: 'numeric',
      });
    },
  },

  props: {
    partner: {
      type: String,
      required: true,
    },
  },
};
</script>
