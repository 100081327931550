<template>
  <main>
    <b-container class="ombre">
      <h1
        class="text-center"
        v-html="$t('stats.title')"
      ></h1>

      <div v-if="!loading">
        <div
          id="statsMenu"
          class="float-right"
        >
          <b-dd
            right
            variant="outline-secondary"
            :text="`${year}`"
          >
            <b-dd-item
              v-for="y in years"
              :key="y"
              :href="`#${y}`"
              class=" "
              @click="loadStats(y)"
            >
              <span v-text="y"></span>
            </b-dd-item>
          </b-dd>
        </div>
        <h2 v-html="`${$t('stats.total')} ${year}`"></h2>

        <b-row>
          <b-col
            lg="6"
          >
            <div class="col-12 col-lg-10 alert alert-secondary mx-auto">
              <p
                id="total"
                class="h2 text-center"
                v-text="$n(total.all, 'eur')"
              ></p>
              <b-tooltip
                placement="left"
                target="total"
                triggers="hover"
              >
                <span
                  v-html="`${$n(total.onetime, 'eur')} ${$t('stats.onetime')}<br />
                    ${$n(total.monthly, 'eur')} ${$t('stats.monthly')}`"
                ></span>
              </b-tooltip>

              <dl class="row">
                <dt
                  class="col-8 text-right"
                  v-html="$t('stats.avg')"
                ></dt>
                <dd
                  id="avg"
                  class="col-4"
                >
                  <span v-text="$n(avg.all, 'eur')"></span>
                  <b-tooltip
                    placement="left"
                    target="avg"
                    triggers="hover"
                  >
                    <span
                      v-html="`${$n(avg.onetime, 'eur')} ${$t('stats.onetime')}<br />
                        ${$n(avg.monthly, 'eur')} ${$t('stats.monthly')}`"
                    ></span>
                  </b-tooltip>
                </dd>
                <dt
                  class="col-8 text-right"
                  v-html="$t('stats.donators.all')"
                ></dt>
                <dd
                  id="donators"
                  class="col-4"
                >
                  <span v-text="donators.all"></span>
                  <b-tooltip
                    placement="left"
                    target="donators"
                    triggers="hover"
                  >
                    <span
                      v-html="`${donators.onetime} ${$t('stats.onetime')}<br />
                        ${donators.monthly} ${$t('stats.monthly')}`"
                    ></span>
                  </b-tooltip>
                </dd>
                <dt
                  v-show="year === Number($t('year.current'))"
                  class="col-8 text-right"
                  v-html="$t('stats.donators.active')"
                ></dt>
                <dd
                  v-show="year === Number($t('year.current'))"
                  class="col-4"
                >
                  <span v-text="donators.active"></span>
                </dd>
              </dl>
            </div>
            <p
              class="col-12 col-lg-10 alert alert-info mx-auto"
              v-html="$t('stats.noVirChq')"
            ></p>
          </b-col>
          <b-col lg="6">
            <div class="col-12 p-0">
              <Donators :list="list" />
            </div>
          </b-col>
        </b-row>

        <b-row class="graph-donators">
          <b-col lg="6">
            <h3 v-html="`${$t('stats.graphAll.title')} ${$t('stats.graphAll.year')}`"></h3>
            <Chart
              :data="chart.all"
              label="graphAll"
              :bound="1000"
              :donators="donators"
            />
          </b-col>
          <b-col lg="6">
            <h3 v-html="$t('stats.graphMonth.title')"></h3>
            <Chart
              :data="chart.month"
              label="graphMonth"
              :bound="100"
              :donators="donators"
            />
          </b-col>
        </b-row>

        <h3>
          <span v-html="$t('stats.graphAll.title')"></span>
          <b-dd
            right
            variant="outline-secondary"
            :text="$t(chart.view)"
          >
            <b-dd-item-btn
              @click="chart.view = 'stats.graphAll.lastMonth'"
            >
              <span v-html="$t('stats.graphAll.lastMonth')"></span>
            </b-dd-item-btn>
            <b-dd-item-btn
              @click="chart.view = 'stats.graphAll.lastQuarter'"
            >
              <span v-html="$t('stats.graphAll.lastQuarter')"></span>
            </b-dd-item-btn>
            <b-dd-item-btn
              @click="chart.view = 'stats.graphAll.year'"
            >
              <span v-html="$t('stats.graphAll.year')"></span>
            </b-dd-item-btn>
          </b-dd>
        </h3>
        <!-- Last month -->
        <b-row v-if="chart.view === 'stats.graphAll.lastMonth'">
          <b-col class="graph-donators graph-day">
            <Chart
              :data="chart.dayA.slice(Math.max(0, chart.end - 30), chart.end)"
              label="graphAll"
              :bound="1000"
              :donators="donators"
            />
            <h3 v-html="`${$t('stats.graphDay.title')} ${$t(chart.view)}`"></h3>
            <Chart
              :data="chart.day.slice(Math.max(0, chart.end - 30), chart.end)"
              label="graphDay"
              :bound="100"
              :donators="donators"
            />
          </b-col>
        </b-row>

        <!-- Last quarter -->
        <b-row v-if="chart.view === 'stats.graphAll.lastQuarter'">
          <b-col class="graph-donators graph-day">
            <Chart
              :data="chart.dayA.slice(Math.max(0, chart.end - 90), chart.end)"
              label="graphAll"
              :bound="1000"
              :donators="donators"
            />
            <h3 v-html="`${$t('stats.graphDay.title')} ${$t(chart.view)}`"></h3>
            <Chart
              :data="chart.day.slice(Math.max(0, chart.end - 90), chart.end)"
              label="graphDay"
              :bound="100"
              :donators="donators"
            />
          </b-col>
        </b-row>

        <!-- This year -->
        <b-row v-if="chart.view === 'stats.graphAll.year'">
          <b-col class="graph-donators graph-day">
            <Chart
              :data="chart.dayA"
              label="graphAll"
              :bound="1000"
              :donators="donators"
            />
            <h3 v-html="`${$t('stats.graphDay.title')} ${$t(chart.view)}`"></h3>
            <Chart
              :data="chart.day"
              label="graphDay"
              :bound="100"
              :donators="donators"
            />
          </b-col>
        </b-row>
      </div>

      <div
        v-else
        class="text-center m-5 p-5"
      >
        <div
          class="spinner-border fc-g6"
          role="status"
          style="width: 4rem; height: 4rem;"
        >
          <span class="sr-only">Chargement…</span>
        </div>
      </div>
    </b-container>
  </main>
</template>

<script>
import Chart from '../components/Chart.vue';
import Donators from '../components/Donators.vue';

export default {
  components: {
    Chart,
    Donators,
  },

  data() {
    const year = Number(this.activeYear());
    const archive = year !== Number(this.$t('year.current'));
    const [total, avg, donators] = [{}, {}, { active: 0 }];

    ['all', 'onetime', 'monthly', 'peertube', 'mobilizon'].forEach((k) => {
      total[k] = 0;
      avg[k] = 0;
      donators[k] = 0;
    });

    return {
      type: ['all', 'onetime', 'monthly', 'peertube', 'mobilizon'],
      list: [],
      loading: true,
      total,
      avg,
      donators,
      year,
      years: [...Array(Number(this.$t('year.current')) - 2008)].map((v, i) => 2009 + i),
      chart: {
        view: 'stats.graphAll.lastMonth',
        end: !archive ? this.dayNumber() : 366,
        month: [...Array(12).keys()].map(m => this.chartObject(m, true)),
        all: [...Array(12).keys()].map(m => this.chartObject(m, true)),
        day: [...Array(Number(366)).keys()].map(d => this.chartObject(d, false)),
        dayA: [...Array(Number(366)).keys()].map(d => this.chartObject(d, false)),
      },
      dateFormat: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
    };
  },

  mounted() {
    if (!document.getElementById('app').hasAttribute('data-server-rendered')) {
      if (this.years.indexOf(Number(this.$t('hash'))) > -1) {
        this.year = Number(this.$t('hash'));
      }
      fetch(`https://soutenir.framasoft.org/dons/${this.year}.json`,
        { cache: (this.year === Number(this.$t('year.current')) ? 'no-cache' : 'force-cache') })
        .then(res => res.json())
        .then((data) => {
          this.donators.active = data.donators;
          this.list = data.list;
          this.updateStats();
          this.loading = false;
        })
        .catch((err) => { console.error(err) }); // eslint-disable-line
    }
  },

  methods: {
    activeYear() {
      return (this.year !== undefined) ? this.year.toString() : this.$t('year.current');
    },

    chartObject(i, isAMonth) {
      const type = ['all', 'onetime', 'monthly', 'peertube', 'mobilizon'];
      const o = {
        name: isAMonth
          ? new Date(Date.UTC(2018, i, 1, 1, 0, 0))
            .toLocaleDateString(this.$t('lang'), { month: 'short' })
          : this.$d(new Date(new Date(this.activeYear()).getTime() + i * 864e5),
            this.dateFormat, this.$t('lang')),
      };
      type.forEach((t) => { o[t] = null; });
      return o;
    },

    dayNumber(date) {
      const d = date !== undefined
        ? date
        : new Date();
      return (Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
        - Date.UTC(d.getFullYear(), 0, 0))
        / 864e5;
    },

    updateStats() {
      for (let i = 0; i < this.list.length; i += 1) {
        const m = this.list[i].date.substring(5, 7) - 1;
        const d = this.dayNumber(new Date([
          this.list[i].date.substring(0, 4),
          this.list[i].date.substring(5, 7),
          this.list[i].date.substring(8, 10),
        ])) - 1;
        const amount = Math.round(this.list[i].don);
        let campaign = 'onetime';
        if (/peertube/.test(this.list[i].campaign)) { campaign = 'peertube'; }
        if (/mobilizon/.test(this.list[i].campaign)) { campaign = 'mobilizon'; }

        this.total.all += amount;
        this.chart.month[m].all += amount;
        this.chart.day[d].all += amount;

        if (this.list[i].monthly > 0) {
          this.total.monthly += amount;
          this.chart.month[m].monthly += amount;
          this.chart.day[d].monthly += amount;
          this.donators.monthly += 1;
        } else {
          this.total[campaign] += amount;
          this.chart.month[m][campaign] += amount;
          this.chart.day[d][campaign] += amount;
          this.donators[campaign] += 1;
        }
      }

      this.donators.all = this.list.length;
      this.donators.onetime = this.donators.all - this.donators.monthly;
      const end = {
        month: this.archive
          ? 12
          : Math.min(new Date().getMonth() + 1, 12),
        day: this.archive
          ? this.chart.day.length
          : Math.min(this.dayNumber() + 1, this.chart.day.length),
      };

      for (let j = 0; j < 5; j += 1) { // not forEach
        const k = ['all', 'onetime', 'monthly', 'peertube', 'mobilizon'][j];
        this.avg[k] = Math.round(this.total[k] / (this.donators[k] + 1));

        for (let i = 0, a = 0; i < end.month; i += 1) {
          // Chart all
          if (this.chart.month[i][k] > 0) {
            a += this.chart.month[i][k];
            this.chart.all[i][k] = a;
          }
        }

        for (let i = 0, a = 0; i < end.day; i += 1) {
          // Chart dayA
          if (this.chart.day[i][k] > 0) {
            a += this.chart.day[i][k];
            this.chart.dayA[i][k] = a;
          }
        }
      }
    },

    loadStats(year) {
      this.year = year;
      this.archive = year !== Number(this.$t('year.current'));
      this.chart.end = !this.archive ? this.dayNumber() : 366;

      /* Reinit */
      this.list = [];
      this.type.forEach((k) => {
        this.total[k] = null;
        this.avg[k] = null;
        this.donators[k] = null;
      });

      for (let i = 0; i < 12; i += 1) {
        this.type.forEach((k) => {
          this.chart.month[i][k] = null;
          this.chart.all[i][k] = null;
        });
      }
      for (let i = 0; i < 366; i += 1) {
        this.chart.day[i].name = this
          .$d(new Date(new Date(this.activeYear()).getTime() + i * 864e5),
            this.dateFormat, this.$t('lang'));
        this.chart.dayA[i].name = this
          .$d(new Date(new Date(this.activeYear()).getTime() + i * 864e5),
            this.dateFormat, this.$t('lang'));
        this.type.forEach((k) => {
          this.chart.day[i][k] = null;
          this.chart.dayA[i][k] = null;
        });
      }

      fetch(`https://soutenir.framasoft.org/dons/${year}.json`, { cache: 'force-cache' })
        .then(response => response.json())
        .then((data) => {
          this.list = data.list;
          this.updateStats();
        }).catch((err) => {
          console.error(err); // eslint-disable-line
        });
    },
  },
};
</script>

<style lang="scss">
#stats dt {
  white-space: normal;
}

#statsMenu {
  .dropdown-menu {
    min-width: 0;
  }
}

.table-donators {
  border: 1px solid #ddd;
  font-size: 12px;

  thead {
    border-bottom: 2px solid #ddd;
  }

  thead, tbody {
    display: block;
    white-space: nowrap;

    th:nth-child(1),
    td:nth-child(1) { width: 145px; }
    th:nth-child(2),
    td:nth-child(2) { width: 65px; }
    th:nth-child(3),
    td:nth-child(3) { width: 30px; }
    th:nth-child(4),
    td:nth-child(4) { width: 42px; }
  }

  tbody {
    max-height: 400px;
    overflow-y: scroll;

    td:nth-child(5) { width: 100%; }
    tr:first-child td {
      border-top: none;
    }
  }
}

.table.table-donators thead th {
  border: none;
}

.graph-donators {
  text {
    font-size: 10px;
  }

  .la-legend {
    width: 80%;
    bottom: -5px !important;
    font-size: smaller;
  }
}
.graph-day {
  g[text-anchor] g {
    text, line {
      display: none;
    }
  }
  g[text-anchor] g:nth-of-type(28n+1) {
    text, line {
      display: block;
    }
  }
}
</style>
